<template>
  <div>
    <BModal
      id="modal-topup"
      ref="modal-topup"
      no-stacking
      centered
      hide-footer
      hide-header
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
    >
      <BRow class="justify-center my-1 flex flex-column">
        <BCol class="d-flex justify-content-center">
          <strong class="text-black text-xl">
            {{ titleModal }}
          </strong>
        </BCol>
        <BCol class="d-flex justify-content-center my-1">
          <div class="text-black text-md">
            {{ subDescription }}
          </div>
        </BCol>
        <BCol class="justify-content-center">
          <ValidationObserver #default="{ invalid }">
            <BFormGroup>
              <template #label>
                <div class="text-black flex items-center">
                  <strong class="text-md font-semibold">Nominal</strong>
                  <strong class="text-primary">*</strong>
                </div>
              </template>
              <ValidationProvider
                #default="{ errors }"
                name="Nominal"
                :rules="{ required: true, minAmount: modalType }"
              >
                <BFormInput
                  v-model="data.topup"
                  :placeholder="placeholder"
                  :state="errors.length > 0 ? false : null"
                  @keypress="isNumber($event)"
                  @input="formatCurrency"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <div>
                  <small
                    v-if="remainingBalances"
                    class="text-danger"
                  >
                    {{ remainingBalancesMessage }}
                  </small>
                </div>
                <div>
                  <small
                    v-if="messageNotEnoughBalance"
                    class="text-danger"
                  >
                    {{ messageNotEnoughBalance }}
                  </small>
                </div>
              </ValidationProvider>
              <BCol
                v-if="
                  data.possible.withdraw_possibilites === 0 &&
                    modalType !== 'withdrawal'
                "
                class="justify-between p-0 mt-[5px]"
              >
                <div class="text-danger flex">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/danger-red.svg"
                    alt="Komerce"
                    width="15"
                  >
                  <small class="ml-[5px]">
                    Maksimal top up {{ IDR(maxTopup) }}
                  </small>
                </div>
                <p class="mb-1">
                  <b>Sisa saldo : {{ IDR(remainingBalance) }}</b> misal ditarik
                </p>
                <div class="bg-[#FFEDED] p-1 mb-1 rounded-lg">
                  Maaf kami batasi maksimal penarikan akunmu agar tersisa saldo
                  setidaknya sebesar
                  <b class="text-danger">{{
                    IDR(data.possible.ideal_balance)
                  }}</b>
                  buat menghindari minus
                </div>
                <div class="border-dashed border-2 rounded-lg p-1 mb-1">
                  <p class="mb-1">
                    Angka ini didapat dari total nilai ongkir berangkat atas
                    orderan yang sudah diputuskan RETUR namun barangnya
                    <b>belum sampai</b> ke gudangmu yakni
                    <b>{{ IDR(data.possible.potency_retur) }}</b> total tersebut
                    kami bandingkan dengan total
                    <b>potensi pendapatanmu</b> yang akan diterima dari orderan
                    yang berstatus <b>DIKIRIM</b> yang pengirimannya sejauh ini
                    belum ada problem yakni
                    <b class="text-success">{{
                      IDR(data.possible.potency_income)
                    }}</b>
                  </p>
                  <p>
                    Di Komship enaknya tuh kalau Retur barang
                    <b>udah balik</b> lagi ke gudang baru kita minuskan ongkir
                    berangkatnya, belum lagi bisa di <b>claim</b> pula.
                  </p>
                </div>
              </BCol>
              <BCol
                v-if="modalType === 'withdrawal'"
                class="border rounded-lg p-1 my-2"
                style="border-color: #E2E2E2 !important"
              >
                <BPopover
                  triggers="hover"
                  target="popover-saldo-pending"
                  placement="topRight"
                >
                  Saldo ini belum dapat ditarik, namun bisa digunakan untuk
                  transaksi. Pihak penyedia layanan segera memprosesnya kembali
                  ke kartu debit kamu
                </BPopover>
                <div
                  class="flex items-center justify-between font-semibold text-black ml-1"
                >
                  <div>Saldo Komcards</div>
                  <div>{{ IDR(balanceDetail.monit_balance, 2, 2) }}</div>
                </div>
                <div class="flex items-center justify-between  text-black ml-2">
                  <div>Voucher Iklan</div>
                  <div class="text-primary">
                    {{ IDR(balanceDetail.voucher_balance, 2, 2) }}
                  </div>
                </div>
                <div class="flex items-center justify-between text-black ml-2">
                  <div class="flex items-center gap-3">
                    <div>Saldo Pending</div>
                    <img
                      id="popover-saldo-pending"
                      src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                      alt="Komerce"
                      width="20"
                      class="cursor-pointer"
                    >
                  </div>
                  <div class="text-primary">
                    {{ IDR(balanceDetail.hold_balance, 2, 2) }}
                  </div>
                </div>
                <div class="flex items-center justify-between  text-black ml-2">
                  <div>Saldo Mengendap</div>
                  <div class="text-primary">
                    {{ IDR(balanceDetail.balance_settle, 2, 2) }}
                  </div>
                </div>
                <div
                  class="flex items-center justify-between font-semibold text-black mt-1 border-[#FEF2CD] bg-[#FFF8E1] rounded-lg p-1"
                >
                  <div>Saldo yang bisa ditarik</div>
                  <div class="font-semibold">
                    {{ IDR(balanceDetail.effective_balance, 2, 2) }}
                  </div>
                </div>
              </BCol>
              <BCol
                v-if="modalType !== 'withdrawal'"
                class="flex flex-row justify-between p-0 mt-[5px]"
              >
                <div class="text-black text-md">
                  {{
                    modalType === 'withdrawal'
                      ? 'Saldo Komcards'
                      : 'Saldo Kompay'
                  }}
                </div>
                <div class="text-black text-md font-semibold">
                  {{
                    modalType === 'withdrawal'
                      ? IDR(balanceDetail.monit_balance)
                      : IDR(balanceData)
                  }}
                </div>
              </BCol>
            </BFormGroup>
            <BCol class="flex flex-row gap-4 justify-center mt-1">
              <BButton
                variant="outline-primary"
                @click="handleClose"
              >
                Kembali
              </BButton>
              <BButton
                :variant="
                  invalid ||
                    disabled ||
                    data.possible.withdraw_possibilites === 0 ||
                    remainingBalances
                    ? 'secondary'
                    : 'primary'
                "
                type="submit"
                :disabled="
                  invalid ||
                    disabled ||
                    loading.possible ||
                    data.possible.withdraw_possibilites === 0 ||
                    remainingBalances
                "
                @click="checkPossibility()"
              >
                <BSpinner
                  v-if="loading.possible"
                  variant="light"
                  small
                />
                {{ buttonText }}
              </BButton>
            </BCol>
          </ValidationObserver>
        </BCol>
      </BRow>
    </BModal>
    <ModalConfirmation
      :source="source"
      :payload-topup="payload"
      :card-data="cardData"
      :get-cards="getCards"
      :get-mutation="getMutation"
      @resetPayload="resetPayload"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { isNumber } from '@/libs/helpers'
import { komshipAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { required } from '@validations'
import isNaN from 'lodash/isNaN'
import ModalConfirmation from './ModalConfirmation.vue'

extend('minAmount', {
  validate: (value, args) => {
    const { modalType } = args
    const minAmount = modalType === 'add' || modalType === 'topup' || modalType === null
      ? 20000
      : 10000
    const amount = parseFloat(value.replace(/[Rp.p]/g, ''))
    return amount >= minAmount
  },
  params: ['modalType'],
  message: (value, valueContext) => {
    const { modalType } = valueContext
    const minAmount = modalType === 'add' || modalType === 'topup' || modalType === null
      ? 20000
      : 10000
    return `Minimal ${IDR(minAmount)}`
  },
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ModalConfirmation,
  },
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    getCards: {
      type: Function,
      default: () => {},
    },
    getMutation: {
      type: Function,
      default: () => {},
    },
    balanceData: {
      type: Number,
      default: 0,
    },
    balanceDetail: {
      type: Object,
      default: () => ({}),
    },
    modalType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isNumber,
      required,
      isNaN,
      IDR,
      messageMaxTopup: null,
      user: this.$store.state.auth.userData,
      disabled: false,
      data: {
        topup: '',
        possible: {},
      },
      loading: {
        possible: false,
      },
      source: null,
      maxTopupMessage: '',
      remainingBalances: false,
      remainingBalancesMessage: null,
      messageNotEnoughBalance: null,
    }
  },
  computed: {
    balance() {
      return Number(this.data.topup.replace(/[^0-9]/g, ''))
    },
    payload() {
      return {
        card_id: this.cardData.id,
        balance: this.balance,
      }
    },
    remainingBalance() {
      return this.balanceData - this.balance
    },
    maxTopup() {
      return this.balanceData - this.data.possible.ideal_balance
    },
    titleModal() {
      return this.modalType === 'withdrawal'
        ? 'Penarikan Saldo Komcards'
        : 'Top Up Komcards'
    },
    buttonText() {
      return this.modalType === 'withdrawal' ? 'Tarik Saldo' : 'Top Up Sekarang'
    },
    balanceCard() {
      return this.modalType === 'withdrawal'
        ? this.balanceDetail.effective_balance
        : this.balanceData
    },
    placeholder() {
      return this.modalType === 'withdrawal'
        ? 'Minimal Rp. 10.000'
        : 'Minimal Top Up Rp 20.000'
    },
    subDescription() {
      return this.modalType === 'withdrawal'
        ? 'Saldo Komcards yang kamu tarik akan masuk ke saldo Kompay'
        : `${this.cardData.label} (${this.cardData.card_number})`
    },
  },
  methods: {
    async checkPossibility() {
      const remainingBalance = this.balanceDetail - this.balance
      if (this.modalType === 'withdrawal') {
        if (remainingBalance < 10000) {
          this.remainingBalancesMessage = `Sisakan saldo minimal ${IDR(
            10000,
          )} di kartu Anda`
          this.remainingBalances = true
          return
        }
        this.$bvModal.show('modal-confirmation')
        this.source = 'withdrawal'
      } else {
        if (
          this.user.is_komcards === 1
          && this.user.is_komship === 0
          && this.user.is_kompack === 0
        ) {
          this.$bvModal.show('modal-confirmation')
          this.source = 'topup'
          return
        }

        if (this.user.is_komship === 1) {
          this.loading.possible = true
          this.source = 'topup'
          const url = `/v1/partner/withdrawal/check-possible-withdraw?withdrawal_request_nominal=${this.balance}`

          await komshipAxiosIns.get(url).then(res => {
            const { data } = res.data
            this.data.possible = data
            // eslint-disable-next-line camelcase
            if (data.withdraw_possibilites === 1) {
              this.$bvModal.show('modal-confirmation')
            }
            this.loading.possible = false
          })
        }

        if (this.user.is_kompack === 1) {
          this.$bvModal.show('modal-confirmation')
          this.loading.possible = false

          this.source = 'topup'
        }

        if (this.user.is_kompack === 1) {
          this.$bvModal.show('modal-confirmation')
          this.loading.possible = false

          this.source = 'topup'
        }
      }
    },
    handleClose() {
      this.$bvModal.hide('modal-topup')
      this.data.topup = ''
      this.data.possible.withdraw_possibilites = 1
      this.remainingBalances = false
      this.remainingBalancesMessage = null
      this.messageNotEnoughBalance = null
    },
    formatCurrency(value) {
      this.data.possible.withdraw_possibilites = 1
      const newValue = Number(value.replace(/\D/g, ''))
      this.data.topup = isNaN(newValue) ? value : IDR(newValue)
      if (newValue > this.balanceCard) {
        this.messageNotEnoughBalance = 'Maaf saldo anda tidak mencukupi'
        this.disabled = true
        this.remainingBalances = false
      } else {
        this.messageNotEnoughBalance = null
        this.disabled = false
        this.remainingBalances = false
      }
    },
    resetPayload() {
      this.data.topup = ''
    },
  },
}
</script>
